<template>
  <div :class="`dialer-main-loader `">
    <img :src="loaderV3" alt />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaderV3: require("@/assets/images/loaderV5.gif"),
    };
  },
};
</script>

<style lang="scss" scoped>
.dialer-main-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: white;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-animator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.dialer-main-loader {
  img {
    height: 450px;
    width: 450px;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}





</style>
