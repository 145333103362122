<template>
  <!-- Dashboard -->
  <div 
    v-if="conditions.screen==screens.dashboard" 
    style="height: var(--inner-height);width: 100vw;position: fixed;top: 0;right: 0;z-index: 999;-webkit-overflow-scrolling:touch !important"
  >
    <iframe style="height: var(--inner-height);"
      v-show="true"
      :src="link" 
      id="dailer-frame"
      allowfullscreen
      allow="camera;microphone;display-capture;clipboard-read;clipboard-write;" 
      @load="onLoadIframe($event)"
      @error="onIFrameError($event)"
      sandbox="
        allow-downloads
        allow-forms 
        allow-modals 
        allow-orientation-lock 
        allow-pointer-lock 
        allow-popups 
        allow-popups-to-escape-sandbox 
        allow-presentation 
        allow-same-origin 
        allow-scripts 
        allow-top-navigation 
        allow-top-navigation-by-user-activation
      "
    />
  </div>
  <div class="alreadyTab" v-else-if="conditions.screen==screens.already_tab_open">
    <img src="../assets/images/emptyScreenImages/alreadyTab.png" alt="icon"/>
    <p class="alreadyTab-text">
      Oh! It looks like you have already opened me in another tab. If you wish to open a new tab, please close the previous one, as the application is limited to a single tab.
    </p>
    <p class="alreadyTab-text">
      Stay connected, stay productive!
      <button @click="makeActiveTab()">Make active</button>
    </p>
  </div>
  <!-- loading -->
  <div 
    v-else-if="conditions.screen==screens.loading" 
    style="height: 100vh;width: 100vw;position: fixed;top: 0;right: 0;z-index: 999;background-color: white;"
  >
    <LoaderMain />
  </div>
</template>

<script>
import LoaderMain from '../components/LoaderMain.vue'
import { HELPER_FUNCTIONS, PERMISSIONS } from '../utils';
import { LOGGER } from '../service/index';
import Push from 'push.js'
import { DIALER } from '../dialer';
import { mapGetters, mapMutations } from 'vuex';
import { BROADCAST } from '@/broadcast';
import { SET_TOKEN, SET_USER } from '@/store/constants/mutations';
import { GET_CP_DOMAIN, GET_DEVICE_ID, GET_DEVICE_INFO, GET_SERVICE_REGISTRATION, GET_USER_ACCOUNTCODE, GET_USER_DIALER_SERVER, GET_USER_TAB_ID, GET_USER_USERNAME, GET_USER_VB_TOKEN } from '@/store/constants/getters';
import { routes_names } from '@/router/constants/routes-names';

const screens = {
  dashboard: 'dashboard',
  already_tab_open: 'already_tab_open',
  loading: 'loading',
}
var timeout_id;
export default {
  name: 'Dashboard',
  components: {
    LoaderMain,
  },
  data(){
    return {
      conditions: {
        screen: screens.loading,
        show: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_USER_VB_TOKEN,
      GET_USER_USERNAME,
      GET_USER_ACCOUNTCODE,
      GET_DEVICE_ID,
      GET_USER_DIALER_SERVER,
      GET_CP_DOMAIN,
      GET_SERVICE_REGISTRATION,
      GET_DEVICE_INFO,
      GET_USER_TAB_ID
    ]),
    screens(){return screens },
    link(){ 
      return `${this.GET_USER_DIALER_SERVER}/forcelogin?`
        +`vb_token=${this.GET_USER_VB_TOKEN}`
        +`&account=${this.GET_USER_ACCOUNTCODE}`
        +`&username=${this.GET_USER_USERNAME}`
        +`&cp_domain=${this.GET_CP_DOMAIN}`
        +`&device_id=${this.GET_DEVICE_ID}`
    },
  },
  watch: {
    "conditions.screen": {
      immediate: true,
      handler(screen){
        let vm = this
        LOGGER.log('screen',screen)
        this.$emit('screen',screen)
        if(timeout_id) clearTimeout(timeout_id)
        if(screen==screens.dashboard){
          timeout_id = setTimeout(()=>{
            const dailer_frame = document.getElementById('dailer-frame')
            LOGGER.log('dailer_frame',{dailer_frame})
            if(!vm.conditions.show) {
              vm.logout()
            }
          },1 * 60 * 1000)
        }
      }
    },
  },
  methods: {
    ...mapMutations([
      SET_USER,
      SET_TOKEN,
    ]),
    async load(){
      try {
        await HELPER_FUNCTIONS.sleep(1*1000)
        if(this.GET_USER_TAB_ID==this.GET_DEVICE_INFO.tab_id){
          this.conditions.screen=screens.dashboard
        } else {
          await BROADCAST.checkTabExits(this.GET_USER_TAB_ID)
          this.conditions.screen=screens.already_tab_open
        }
      } catch (ex) {
        this.conditions.screen=screens.dashboard
      }
    },
    sendNotfication(message){
      // if(this.GET_SERVICE_REGISTRATION){
      //   this.GET_SERVICE_REGISTRATION.showNotification(message.title ?? '', {
      //     ...message,
      //     body: message.body ?? '',
      //   })
      // } else {
        Push.create(message.title ?? '', {
          ...message,
          onClick() {
            window.focus()
            DIALER?.sendData?.(DIALER.events.send.NOTIFICATION_CLICKED,message?.payload ?? {})
            this.close();
          }
        });
      // }
    },
    makeActiveTab(){
      BROADCAST.makeActiveTab()
      this.conditions.screen=screens.dashboard
    },
    // NOTE - iframe events
    onLoadIframe(){
      this.conditions.show=true
      setTimeout(()=>{
        DIALER?.sendData?.(DIALER.events.send.DESKTOP_PERMISSION_CHANGE,{
          permission: PERMISSIONS.notification,
        })
      }, 3 * 1000)
    },
    onIFrameError(Event){
      LOGGER.log('Event',Event)
    },
    // NOTE - permission events
    onDesktopPermission(permission){
      DIALER?.sendData?.(DIALER.events.send.DESKTOP_PERMISSION_CHANGE,{
        permission,
      })
    },
    // NOTE - dialer events
    logout(){
      this.SET_USER()
      this.SET_TOKEN()
      this.$router.push({
        name: routes_names.login
      })
    },
    CHAT_DESKTOP_NOTIFICATION(message){
      this.sendNotfication(message)
    },
    TEST_DESKTOP_NOTIFICATION(message){
      this.sendNotfication(message)
    },
    DESKTOP_NOTIFICATION(message){
      this.sendNotfication(message)
    },
    INCOMING_SIP_CALL_NOTIFICATION(message){
      this.sendNotfication(message)
    },
    MISSED_SIP_CALL_NOTIFICATION(message){
      this.sendNotfication(message)
    },
    async GET_DESKTOP_NOTIFICATION_PERMISSION(){
      if (!("Notification" in window) && this.permissions.camera !== "prompt") return;
      const permission = await Notification.requestPermission()
      DIALER.sendData(DIALER.events.send.DESKTOP_PERMISSION_CHANGE,{
        permission,
      })
    },
    async CHECK_DESKTOP_NOTIFICATION_PERMISSION(){
      const permission = await navigator.permissions.query({
        name: "notifications",
      })
      DIALER.sendData(DIALER.events.send.DESKTOP_PERMISSION_CHANGE,{
        permission: permission.state,
      })
    },
    SWITCH_ORGANIZATION(message){
      LOGGER.log(message)
    },
    IFRAME_LOADED(){
      this.conditions.show=true
    },
    // NOTE - broadcast events
    onNotActiveTab(){
      this.conditions.screen=screens.already_tab_open
    },
    // windows
    onWindowMessage(Event){
      const data = Event.data
      if(data && data.call_number){
        DIALER?.sendData?.(DIALER.events.send.DIAL_NUMBER,{
          number: data.call_number
        })
      }
    },
  },
  created(){
    window.addEventListener('message',this.onWindowMessage)
    BROADCAST.onnotactivetab=this.onNotActiveTab.bind(this)
    DIALER.onlogout=this.logout.bind(this)
    DIALER.oniframeloaded=this.IFRAME_LOADED.bind(this)
    DIALER.onchatdesktopnotification=this.CHAT_DESKTOP_NOTIFICATION.bind(this)
    DIALER.ontestdesktopnotification=this.TEST_DESKTOP_NOTIFICATION.bind(this)
    DIALER.ondesktopnotification=this.DESKTOP_NOTIFICATION.bind(this)
    DIALER.onincomingsipcallnotification=this.INCOMING_SIP_CALL_NOTIFICATION.bind(this)
    DIALER.onmissedsipcallnotification=this.MISSED_SIP_CALL_NOTIFICATION.bind(this)
    DIALER.ongetdesktopnotificationpermission=this.GET_DESKTOP_NOTIFICATION_PERMISSION.bind(this)
    DIALER.oncheckdesktopnotificationpermission=this.CHECK_DESKTOP_NOTIFICATION_PERMISSION.bind(this)
    DIALER.onswitchorganization=this.SWITCH_ORGANIZATION.bind(this)
    PERMISSIONS.onchangenotification=this.onDesktopPermission.bind(this)
  },
  mounted(){
    LOGGER.log('this.GET_USER_USERNAME',this.GET_USER_USERNAME)
    this.load()
  },
  beforeDestroy(){
    window.removeEventListener('message',this.onWindowMessage)
  },
}
</script>

<style lang="scss">
.login-button-v2{
  min-height:48px !important;
  .dialer-loader{
    transform:translateX(-50%);
    left:50%;
  }
}
</style>
